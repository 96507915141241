import fetch from './index'

export function getFeatures(id): any {
  return fetch.get(`features/erp/${id}/values`)
}

export function getParent(): any {
  return fetch.get('cats/parent')
}

export function getSubCats(id): any {
  return fetch.get(`cats/${id}`)
}
