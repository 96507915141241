import {
  createSlice,
} from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'route',
  initialState: {
    routes: [],
  },
  reducers: {
    setRoutes(state, action) {
      state.routes = action.payload
    },
  }
})

const {
  actions,
  reducer,
} = slice;

export const {
  setRoutes,
} = actions

export default reducer