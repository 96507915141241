import routes from '../../../route.config'

export default function(origin = []) {
  return origin.map(v => {
    return {
      key: v.menuName,
      icon: v.menuName,
      title: v.menuCHNName,
      children: v.childrenMenus.map(child => {
        const {
          menuName: key,
        } = child
        const route = routes.find(r => r.key === key)

        return {
          key,
          title: child.menuCHNName,
          path: route?.path || `/${key.toLowerCase()}`,
          link: route?.link || `/${key.toLowerCase()}`,
        }
      }),
    }
  })
}