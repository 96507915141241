import React, { cloneElement } from 'react'

class RouteTransition extends React.Component<any, any> {
  constructor(props) {
    super(props)

    this.state = {
      pathname: props.location.pathname
    }
  }

  static getDerivedStateFromProps(props) {
    return props.visible ? {
      pathname: props.location.pathname
    } : null
  }

  shouldComponentUpdate(...[, nextState]) {
    return nextState.pathname !== this.state.pathname
  }

  render() {
    const {
      children,
    } = this.props
    const {
      pathname,
    } = this.state

    return cloneElement(children as React.ReactElement, {
      pathname,
    })
  }
}

export default RouteTransition
