import React, { useEffect, useState } from 'react';
import {
  useHistory,
} from 'react-router-dom'
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import {
  Input,
  Button,
} from 'antd'

import CaptchaButton from '../components/CaptchaButton'
import styles from './index.less';

import {
  login,
} from '../services/users'
import {
  set,
} from '../utils/session'

function Login() {
  const [phoneNum, setPhoneNum] = useState()
  const [captcha, setCaptcha] = useState()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const history = useHistory()

  function handlePhoneNumChange(e) {
    setPhoneNum(e.target.value.trim())
  }

  function handleCaptchaChange(e) {
    setCaptcha(e.target.value.trim())
  }

  function handleLogin() {
    setLoading(true)
  }

  useEffect(() => {
    if (!loading) return

    login({
      authToken: captcha,
      loginType: 1,
      outerId: phoneNum
    }).then(data => {
      set(data)
      setLoading(false)
      history.replace('/')
    }).catch(() => {
      setLoading(false)
    })
  }, [loading])

  return (
    <div className={styles.container}>
      <div className={styles.colorMask}></div>
      <div className={styles.mask}>
        <div className={styles.login}>
          <p className={styles.title}>登录</p>
          <Input
            value={phoneNum}
            className={classNames(styles.mobile, styles.input)}
            placeholder="请输入手机号"
            maxLength={11}
            onChange={handlePhoneNumChange}
          />
          <div className={styles.codeLine}>
            <Input
              value={captcha}
              className={classNames(styles.input, styles.code)}
              placeholder="请输入验证码"
              maxLength={4}
              onChange={handleCaptchaChange}
            />
            <CaptchaButton phoneNum={phoneNum} className={styles.captchaBtn} />
          </div>
          <Button
            disabled={!phoneNum || !captcha}
            type="primary"
            className={styles.btnLogin}
            onClick={handleLogin}
          >
            登录
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Login