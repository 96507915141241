
import {
  configureStore,
} from '@reduxjs/toolkit'
import {
  combineReducers,
} from 'redux'

import reducer from '../reducers'

const appReducer = combineReducers(reducer)

const store = configureStore({
  reducer: (state, action) => {
    if (action.type === 'USER_LOGOUT') {
      state = undefined
    }

    return appReducer(state, action)
  },
})

export default store