import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import Icon from '../Icon'
import styles from './index.less'

import {
  getUserInfo,
  logout,
} from '../../reducers/user'
import {
  getRole,
} from '../../utils/auth'
import adapter from '../../utils/adapter'

function LayoutUser() {
  const userInfo = useSelector<any, any>(state => state?.user?.userInfo)
  const dispatch = useDispatch()

  const {
    opTag,
    sellerLevel,
    avatarUrl,
    nickName,
    anniversaryTime,
    anniversaryOrderPrice,
  } = userInfo
  const role = getRole(opTag)
  const level = ['basic', 'premium', 'premium'][sellerLevel]

  useEffect(() => {
    dispatch(getUserInfo())
  }, [])

  function handleLogout() {
    dispatch(logout())
  }

  return (
    <div className={styles.container}>
      <div className={styles.roleWrap}>
        <Icon onClick={handleLogout} className={styles.logout} type="logout" />
        {
          role ? (
            <span className={styles.role}>{role}</span>
          ) : null
        }
      </div>
      <div className={styles.avatarWrap}>
        {
          avatarUrl ? (
            <img className={styles.avatar} alt="avatar" src={avatarUrl} />
          ) : null
        }
        {
          level ? (
            <img className={styles.level} alt="level" src={`https://img.zb100.com/gemstore/${level}_v2.png`} />
          ) : null
        }
      </div>
      {
        nickName ? (
          <p className={styles.name}>{nickName}</p>
        ) : null
      }
      {
        anniversaryTime ? (
          <p className={styles.data}>生效期: {moment(anniversaryTime).format('YYYY.MM.DD')}</p>
        ) : null
      }
      {
        anniversaryOrderPrice ? (
          <p className={styles.data}>总成交: {adapter('precision', anniversaryOrderPrice)(100)}</p>
        ) : null
      }
    </div>
  )
}

export default LayoutUser
