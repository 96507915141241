import {
  createSlice,
} from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'tab',
  initialState: {
    tabs: [],
  },
  reducers: {
    setTabs(state, action) {
      state.tabs = action.payload
    },
    closeTab(state, action) {
      state.tabs = state.tabs.filter(v => v.key !== action.payload)
    },
  }
})

const {
  actions,
  reducer,
} = slice;

export const {
  setTabs,
  closeTab,
} = actions

export default reducer