import React from 'react'
import {
  Result,
} from 'antd'

import Icon from '../Icon'
import styles from './index.less'

function HelpNotFound() {
  return (
    <div className={styles.container}>
      <Result
        status="error"
        title="404"
        style={{
          background: 'none',
        }}
        subTitle="抱歉，您访问的页面不存在"
        icon={(
          <div className={styles.iconWrap}>
            <Icon className={styles.icon} type="404" />
          </div>
        )}
      />
    </div>
  )
}

export default HelpNotFound