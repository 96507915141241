import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getHomeCommodityBySeller as getList,
} from '../services/commodities'

  
export const getHomeCommodityBySeller = createAsyncThunk<any, any>(
  'commodity/get',
  async (params) => {
   return await getList(params)
  }
)
  
const commodit = createSlice({
  name: 'commodity',
  initialState: {
    commodata: {}
  },
  reducers: {
  },
  
  extraReducers: {
    [getHomeCommodityBySeller.fulfilled as any]: (state, action) => {
      state.commodata = action.payload
    }
  }
})

const {
  reducer,
} = commodit;


export default reducer