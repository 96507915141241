import React, {
  useState,
} from 'react'
import {
  useSelector,
  useDispatch,
} from 'react-redux'
import classnames from 'classnames'
import {
  Tooltip
} from 'antd'

import Icon from '../Icon'
import styles from './index.less'

import {
  changeExpand,
} from '../../reducers/help'

function HelpHeader() {
  const expand = useSelector<any>(state => state.help.expand)
  const dispatch = useDispatch()
  const [tipVisible, setTipVisible] = useState(false)

  function handleExpandChange() {
    setTipVisible(expand as boolean)
    dispatch(changeExpand(!expand))
  }

  function handleTipVisibleChange(visible) {
    setTipVisible(!expand && visible)
  }

  return (
    <div className={classnames(styles.helpHead, {
      [styles.expand]: expand
    })}>
      <span className={styles.helpHeadTitle}>帮助中心</span>
      <Tooltip
        visible={tipVisible}
        title={(
          <span className={styles.tip}>展开帮助中心</span>
        )}
        onVisibleChange={handleTipVisibleChange}
        placement="left"
        color="#fff"
        arrowPointAtCenter
      >
        <Icon
          className={styles.helpHeadIcon}
          type="collapse"
          onClick={handleExpandChange}
        />
      </Tooltip>
    </div>
  )
}

export default HelpHeader