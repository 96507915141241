import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getMenu as getMenuAPI,
} from '../services/menu'
import adapter from '../utils/adapter'
import {
  setRoutes,
} from './route'

export const getMenu = createAsyncThunk(
  'auths/menu',
  async (...[, { dispatch }]) => {
    const data = await getMenuAPI()
    const menus = adapter('menus', data)
    const routes = adapter('routes', menus)
    dispatch(setRoutes(routes))
    return menus
  }
)

const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    menus: []
  },
  reducers: {
  },
  extraReducers: {
    [getMenu.fulfilled as any]: (state, action) => {
      state.menus = action.payload
    }
  }
})

const {
  reducer,
} = menuSlice;

export default reducer