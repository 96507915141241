import React from 'react'
import { useSelector } from "react-redux"
import { Route } from "react-router-dom"

export default function useHideHelp() {
  const routes = useSelector<any, any[]>(state => state?.route?.routes)

  return routes.filter(v => !v.help).map(v => (
    <Route key={v.key} exact path={v.path} />
  ))
}