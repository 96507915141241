import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Menu,
} from 'antd'
import {
  useHistory,
  useLocation,
} from 'react-router-dom'

import Icon from '../Icon'
import styles from './index.less'

import {
  getMenu,
} from '../../reducers/menu'
import {
  exactStrictMatchPath,
} from '../../utils'

function LayoutMenu() {
  const [selectedKeys, setSelectedKeys] = useState([])
  const menus = useSelector<any, any[]>(state => state?.menu?.menus)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    dispatch(getMenu())
  }, [])

  useEffect(() => {
    const menu = menus?.find?.(v => v.children.some(v => exactStrictMatchPath(location.pathname, v.path)))
    const subMenu = menu?.children?.find?.(v => exactStrictMatchPath(location.pathname, v.path))
    setSelectedKeys([menu?.key, subMenu?.key])
  }, [location, menus])

  function handleVisit(page) {
    return () => {
      if (selectedKeys?.[1] === page.key) return 
      history.push(page.path)
    }
  }

  return (
    <Menu selectedKeys={selectedKeys}>
      {
        (menus as any[]).map(menu => (
          <Menu.SubMenu key={menu.key} title={(
            <div className={styles.menu}>
              <div className={styles.menuInner}>
                <Icon className={styles.icon} type={menu.icon} />
                <p className={styles.name}>{menu.title}</p>
              </div>
            </div>
          )}>
            {
              menu.children.map(child => (
                <Menu.Item onClick={handleVisit(child)} className={styles.menuItem} key={child.key}>
                  {child.title}
                </Menu.Item>
              ))
            }
          </Menu.SubMenu>
        ))
      }
    </Menu>
  )
}

export default LayoutMenu
