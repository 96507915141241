import route from '../../../route.config'
import {
  toPascalCase,
} from '../../utils'

export default function(menus = []) {
  function getDefault(v) {
    return {
      help: true,
      component: toPascalCase(v.key),
      path: `/${v.key.toLowerCase()}`,
    }
  }

  const subMenus = menus.reduce((acc, v) => {
    acc.push(...v.children)
    return acc
  }, [])
  const routes = subMenus.map(v => {
    const rc = route.find(r => r.key === v.key)

    return {
      ...getDefault(v),
      ...v,
      ...rc,
    }
  }).concat(route.filter(v => subMenus.every(s => s.key !== v.key)).map(v => {
    return {
      ...getDefault(v),
      ...v,
    }
  }))

  return routes
}