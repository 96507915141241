import fetch from './index'

export function getPromoters(): any {
  return fetch.get('seller/users/promoters')
}

export function getCustomers(params): any {
  return fetch.get('seller/users/infos', {
    params,
  })
}

export function setMember(uid, flag) {
  return fetch.put(`seller/users/members/${uid}/${flag}`)
}

export function setPromoter(uid, flag) {
  return fetch.put(`seller/users/promoters/${uid}/${flag}`)
}

export function editCustomer(params) {
  return fetch.put('seller/users', params)
}

export function setDiscount(discount) {
  return fetch.put(`seller/users/members/discount/${discount}`)
}

export function getDiscount() {
  return fetch.get('seller/users/members/discount')
}