import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

import RecentVisit from '../RecentVisit'
import HelpHeader from '../HelpHeader'
import styles from './index.less'

import useHideHelp from '../../hooks/useHideHelp'

function LayoutHeader() {
  const routes = useSelector<any, any[]>(state => state?.route?.routes)
  const hideHelp = useHideHelp()

  return (
    <div className={styles.container}>
      <div className={styles.recentVisit}>
        <RecentVisit />
      </div>
      {
        routes.length ? (
          <Switch>
            {hideHelp}
            <Route path="*">
              <div className={styles.help}>
                <HelpHeader />
              </div>
            </Route>
          </Switch>
        ) : null
      }
    </div>
  )
}

export default LayoutHeader