import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
  
import {
  getWish as getWishdata,
} from '../services/activity'
  
    
export const getWish = createAsyncThunk(
  'activity/get',
  async () => {
    return await getWishdata()
  }
)
    
const activity = createSlice({
  name: 'activity',
  initialState: {
    list: {},
  },
  reducers: {
  },
  
  extraReducers: {
    [getWish.fulfilled as any]: (state, action) => {
      state.list = action.payload
    }
  }
})

const {
  reducer,
} = activity;

  
export default reducer