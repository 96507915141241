import { Link } from 'react-router-dom';
import { Result, Button } from 'antd';
import React from 'react';
export default (props) => {
  const {
    onBackHome = () => {},
  } = props

  return (
    <Result
      status="404"
      title="404"
      style={{
        background: 'none',
      }}
      subTitle="抱歉，您访问的页面不存在"
      extra={
        <Link to="/" onClick={onBackHome}>
          <Button type="primary">返回首页</Button>
        </Link>
      }
    />
  )
};
