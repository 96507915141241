import React from 'react'
import classnames from 'classnames';

import styles from './index.less'

function PageVisible(props) {
  const {
    visible,
    children,
  } = props

  return (
    <div className={classnames(styles.container, {
      [styles.hide]: !visible
    })}>{children}</div>
  )
}

export default PageVisible
