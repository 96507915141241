import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getUserInfo as getUserInfoAPI,
} from '../services/users'
import {
  clear,
} from '../utils/session'
import history from '../utils/history'
import {
  isSeller,
} from '../utils/auth'

const hrefs = {
  local: '/login',
  daily: 'https://daily.admin.zb100.com/',
  prod: 'https://admin.zb100.com/'
}

export const getUserInfo = createAsyncThunk<any>(
  'users/info',
  async () => {
    const userInfo: any = await getUserInfoAPI()


    if (!isSeller(userInfo?.opTag)) {
      clear();
      window.location.href = hrefs[STAGE]
    }

    return userInfo
  }
)

export const logout = createAsyncThunk(
  'users/logout',
  async (...[, { dispatch }]) => {
    clear()
    history.push('/login')
    dispatch({
      type: 'USER_LOGOUT'
    })
  }
)

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: {},
  },
  reducers: {
  },
  extraReducers: {
    [getUserInfo.fulfilled as any]: (state, action) => {
      state.userInfo = action.payload
    },
  }
})

const {
  reducer,
} = userSlice;

export default reducer