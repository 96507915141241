import React, { useEffect, useRef } from 'react'
import classnames from 'classnames'

import Icon from '../Icon'
import styles from './index.less'

function RecentVisitTab(props) {
  const {
    active,
    name,
    unclosable,
    onVisit,
    onClose,
  } = props
  const tab = useRef(null)

  useEffect(() => {
    if (!active) return
    tab.current.scrollIntoView()
  }, [active])

  return (
    <div ref={tab} className={classnames(styles.item, {
      [styles.active]: active
    })}>
      <span onClick={onVisit} className={styles.name}>{name}</span>
      {
        unclosable ? null : (
          <Icon onClick={onClose} className={styles.close} type="close-circle" />
        )
      }
    </div>
  )
}

export default RecentVisitTab
