import React from 'react'

import LayoutUser from '../LayoutUser'
import LayoutMenu from '../LayoutMenu'
import styles from './index.less'

function LayoutLeftSide() {
  return (
    <div className={styles.container}>
      <div className={styles.innerWrap}>
        <LayoutUser />
        <div className={styles.menuWrap}>
          <LayoutMenu />
        </div>
      </div>
    </div>
  )
}

export default LayoutLeftSide