import help from './help'
import menu from './menu'
import user from './user'
import account from './account'
import promoter from './promoter'
import customer from './customer'
import rate from './rate'
import feature from './feature'
import tab from './tab'
import route from './route'
import commodity from './commodity'
import activity from './activity'
import productlist from './product'
import coupon from './coupon'
import commodities from './commodities'

export default {
  help,
  menu,
  user,
  account,
  promoter,
  customer,
  rate,
  feature,
  tab,
  route,
  commodity,
  activity,
  productlist,
  coupon,
  commodities
}