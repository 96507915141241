import {
  message,
} from 'antd'
import { matchPath } from 'react-router-dom'
import {
  logout,
} from '../reducers/user'

export function isNumber(v) {
  return /^(?:[0-9]\.|[1-9][0-9]+\.|\.[0-9]|[0-9])[0-9]*$/.test(v)
}

const sessionOutOfDateErrorHandler = (function() {
  let isHandling = false

  return () => {
    if (isHandling) return
    isHandling = true

    message.error('登录已失效，请重新登录', 4.5, () => {
      isHandling = false
    })
    import('./store').then(module => {
      module.default.dispatch(logout())
    })
  }
}())

export function errorHandler(e, status?) {
  if (status === 6506) {
    sessionOutOfDateErrorHandler()

    return;
  }

  let msg = ''

  try {
    let status = e?.response?.status;
    let statusText = e?.response?.statusText;
    if (status && statusText) {
      msg = `status ${status}: ${statusText }`
    }
  } catch(e) {
    msg = ''
  }

  message.error(e.msg || msg || e.message || '服务器开小差了，请稍后重试', 4.5)
}

export function toPascalCase(str, split = '_') {
  return str.split(split).map(v => v.toLowerCase()).map(v => `${v.charAt(0).toUpperCase()}${v.substr(1)}`).join('')
}

export function toHyphenated(str, hyphen = '_') {
  return str.split(/(?=[A-Z])/).join(hyphen).toLowerCase()
}

export function departValueFromEventTarget(fn) {
  return function(e) {
    return fn(e.target.value)
  }
}

export function extname(filename) {
  return filename.slice(filename.lastIndexOf('.'));
}

export function shouldInputNumber(fn) {
  return name => value => {
    if (value && !isNumber(value)) {
      return
    }

    fn(name, value)
  }
}

export function departValueFromDate(fn) {
  return function(e) {
    return fn(e.format('YYYY-MM-DD'))
  }
}

export function exactStrictMatchPath(pathname, path) {
  return matchPath(pathname, {
    path,
    exact: true,
    strict: true
  })
}

export function isFlag(data, key) {
  let isfalg =  data.findIndex((item)=>{
    if(key === item.key){
      return true;
    }else{
      return false;
    }
  })
  return isfalg
}
