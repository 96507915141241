import React from 'react';
import {
  Spin,
} from 'antd'
import styles from './index.less'

function Loading(props) {
  const {
    size,
  } = props

  return (
    <Spin size={size} wrapperClassName={styles.wrapper} spinning tip="加载中...">
      <div />
    </Spin>
  )
}
export default Loading