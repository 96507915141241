import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import RecentVisitTab from '../RecentVisitTab'
import styles from './index.less'

import {
  setTabs,
} from '../../reducers/tab'
import {
  exactStrictMatchPath,
} from '../../utils'

const homeKey = 'HOME'

function RecentVisit() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const routes = useSelector<any, any[]>(state => state?.route?.routes)
  const tabs = useSelector<any, any[]>(state => state?.tab?.tabs)
  const homeRoute = routes.find(v => v.key === homeKey)
  const home = homeRoute ? {
    ...homeRoute,
    active: true,
    unclosable: true,
  } : null

  function syncPages(pages) {
    dispatch(setTabs(pages))
  }

  useEffect(() => {
    let pages = [...tabs]

    if (!pages.find(v => v.key === homeKey) && home) {
      pages.unshift(home)
    }

    if (pages.every(v => !exactStrictMatchPath(location.pathname, v.path)) && routes.some(r => exactStrictMatchPath(location.pathname, r.path))) {
      pages = [
        ...pages.map(v => ({
          ...v,
          active: false,
        })),
        {
          ...routes.find(r => exactStrictMatchPath(location.pathname, r.path)),
          link: location.pathname,
          active: true,
        }
      ]
    } else {
      pages = [
        ...pages.map(v => {
          const active = exactStrictMatchPath(location.pathname, v.path)

          return {
            ...v,
            link: active ? location.pathname : v.link,
            active,
          }
        })
      ]
    }

    syncPages(pages)
  }, [location, routes])

  function handleVisit(page) {
    return () => {
      history.push(page.link)
    }
  }

  function handleClose(page) {
    return () => {
      const remainPages = tabs.filter(v => v.key !== page.key)
      syncPages(remainPages)
      if (page.active) {
        history.push(remainPages[remainPages.length - 1].link)
      }
    }
  }

  return (
    <div className={styles.container}>
      {
        (tabs as any[]).map(page => {
          return (
            <RecentVisitTab
              key={page.key}
              active={page.active}
              name={page.title}
              unclosable={page.unclosable}
              onVisit={handleVisit(page)}
              onClose={handleClose(page)}
            />
          )
        })
      }
    </div>
  )
}

export default RecentVisit
