import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getFeatures as getFeaturesAPI,
  getParent as getParentAPI,
  getSubCats as getSubCatsAPI,
} from '../services/features'

export const getFeatures = createAsyncThunk<any, any, any>(
  'feature/get',
  async (id) => {
    const features = await getFeaturesAPI(id)

    return {
      [id]: features,
    }
  }
)

export const getAllCats = createAsyncThunk(
  'feature/getAllCats',
  async () => {
    let parent = await getParentAPI();
    let parentList = parent.cats
    let subCat = parentList.map((item) => {
      return getSubCatsAPI(item.id);
    });
    let subCatList = await Promise.all(subCat);
    let featureCat = [];

    subCatList.forEach(({cats: item}) => {
      item.forEach((item1) => {
        featureCat.push(item1);
      })
    });
 
    let catItem = {};

    parentList.forEach((parentItem) => {
      catItem[parentItem.id] = [];
      featureCat.map((fItem) => {
        if (parentItem.id == fItem.parentId) {
          catItem[parentItem.id].push(fItem);
        }
      });
    });
    
    return {
      catItem,
      parentList,
    }
  }
)

const slice = createSlice({
  name: 'feature',
  initialState: {
    features: {},
    parent: {
      parentList: []
    },
    cat: {},
  },
  reducers: {
  },
  extraReducers: {
    [getFeatures.fulfilled as any]: (state, action) => {
      state.features = {
        ...state.features,
        ...action.payload,
      }
    },
    [getAllCats.fulfilled as any]: (state, action) => {
      state.parent.parentList = action.payload.parentList
      state.cat = action.payload.catItem
    },
  }
})

const {
  reducer,
} = slice;

export default reducer