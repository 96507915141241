import fetch from './index'

export function queryCoupons(params) {
  return fetch.get('coupons/sellers/infos', {
    params,
  })
}

export function queryCouponById(params) {
  return fetch.get('coupons/sellers', {
    params,
  })
}

export function createCoupon(params) {
  return fetch.post('coupons/sellers', params)
}

export function updateCoupon(params) {
  return fetch.put('coupons/sellers', params)
}

export function updateCouponState(id, state) {
  return fetch.put(`coupons/sellers/${id}/${state}`)
}

export function createCounponQRCode(params) {
  return fetch.post(`coupons/sellers/code?id=${params.id}`)
}
