import fetch from './index'

export function getAccounts(): any {
  return fetch.get('seller/accounts/infos')
}

export function delAccount(id) {
  return fetch.delete(`seller/accounts/${id}`)
}

export function editAccount(params) {
  return fetch.post('seller/accounts', params)
}
