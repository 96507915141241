import querystring from 'querystring'

import fetch from './index'

export function getRates() {
  return fetch.get('sellers/rate')
}

export function setRentRate(params) {
  return fetch.put(`sellers/rate/rent?${querystring.stringify(params)}`)
}

export function setGiaRate(params) {
  return fetch.put(`sellers/rate/gia?${querystring.stringify(params)}`)
}
