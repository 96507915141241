import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getRates as getRatesAPI
} from '../services/rete'

export const getRates = createAsyncThunk(
  'rate/get',
  async () => {
    return await getRatesAPI()
  }
)

const slice = createSlice({
  name: 'rate',
  initialState: {
    rates: {},
  },
  reducers: {
  },
  extraReducers: {
    [getRates.fulfilled as any]: (state, action) => {
      state.rates = action.payload
    },
  }
})

const {
  reducer,
} = slice;

export default reducer