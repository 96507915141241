import React from 'react'

import styles from './index.less'

function Help() {
  return (
    <div className={styles.container}>
      <p className={styles.text}>帮助中心完善中，请先查看操作指导手册：</p>
      <a className={styles.link} href="https://static.zb100.com/operation/gemstore-help.docx" download="操作指导手册">https://static.zb100.com/operation/gemstore-help.docx</a>
    </div>
  )
}

export default Help
