import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getProduct as getList,
} from '../services/product'

  
export const getProduct = createAsyncThunk<any, any, any>(
  'commodities/get',
  async (params, { getState }) => {
    const querydata:any = getState()
    return await getList({
      ...querydata?.productlist?.querydata,
      pageSize: querydata?.productlist?.querydata?.pageSize || 10,
      nextCursor: querydata?.productlist?.querydata?.nextCursor || 0,
    })
  }
)
  
const product = createSlice({
  name: 'commodities',
  initialState: {
    productdata: [],
    querydata: {}
  },
  reducers: {
    getProductdata(state, action) {
      state.querydata = action.payload
    }
  },
  
  extraReducers: {
    [getProduct.fulfilled as any]: (state, action) => {
      state.productdata = action.payload
    }
  }
})

const {
  actions,
  reducer,
} = product;

export const {
  getProductdata,
} = actions

export default reducer