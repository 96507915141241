import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getCustomers as getCustomersAPI,
  getDiscount as getDiscountAPI,
} from '../services/customers'

export const getCustomers = createAsyncThunk<any, any, any>(
  'customer/get',
  async (params) => {
    return await getCustomersAPI(params)
  }
)

export const getDiscount = createAsyncThunk(
  'customer/getDiscount',
  async () => {
    return await getDiscountAPI()
  }
)

const slice = createSlice({
  name: 'customer',
  initialState: {
    customers: {},
    discount: 100,
  },
  reducers: {
  },
  extraReducers: {
    [getCustomers.fulfilled as any]: (state, action) => {
      state.customers = action.payload
    },
    [getDiscount.fulfilled as any]: (state, action) => {
      state.discount = action.payload
    },
  }
})

const {
  reducer,
} = slice;

export default reducer