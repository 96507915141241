import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getPromoters as getPromotersAPI
} from '../services/customers'

export const getPromoters = createAsyncThunk(
  'promoter/get',
  async () => {
    const data = await getPromotersAPI()
    return data || []
  }
)

const slice = createSlice({
  name: 'promoter',
  initialState: {
    promoters: []
  },
  reducers: {
  },
  extraReducers: {
    [getPromoters.fulfilled as any]: (state, action) => {
      state.promoters = action.payload
    },
  }
})

const {
  reducer,
} = slice;

export default reducer