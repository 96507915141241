import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getAccounts as getAccountsAPI,
} from '../services/accounts'

export const getAccounts = createAsyncThunk(
  'account/get',
  async () => {
    const data = await getAccountsAPI()
    return data.models || []
  }
)

const slice = createSlice({
  name: 'account',
  initialState: {
    accounts: []
  },
  reducers: {
  },
  extraReducers: {
    [getAccounts.fulfilled as any]: (state, action) => {
      state.accounts = action.payload
    },
  }
})

const {
  reducer,
} = slice;

export default reducer