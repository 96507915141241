import React from 'react'
import classnames from 'classnames'

import styles from './index.less'

function Icon(props) {
  const {
    className,
    ...otherProps
  } = props

  return (
    <svg {...otherProps} className={classnames(styles.icon, props.className)} aria-hidden="true">
      <use xlinkHref={`#${props.type}`}></use>
    </svg>
  )
}

export default Icon