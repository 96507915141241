import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  getSellerCommodities as getSellerCommoditiesAPI,
} from '../services/commodities'

export const getCommodity = createAsyncThunk<any, any, any>(
  'commodity/getById',
  async (commodityId) => {
    const data = await getSellerCommoditiesAPI({
      commodityId,
      nextCursor: 0,
      pageSize: 1,
    })
    return data?.models?.[0]
  }
)

const slice = createSlice({
  name: 'commodity',
  initialState: {
    loading: false,
    data: null
  },
  reducers: {
    clear(state) {
      state.loading = false;
      state.data = null
    }
  },
  extraReducers: {
    [getCommodity.fulfilled as any]: (state, action) => {
      state.data = action.payload
      state.loading = false
    },
    [getCommodity.pending as any]: (state) => {
      state.loading = true
    },
    [getCommodity.rejected as any]: (state) => {
      state.loading = false
    },
  }
})

const {
  actions,
  reducer,
} = slice;

export const {
  clear,
} = actions

export default reducer