import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import LayoutLeftSide from '../components/LayoutLeftSide'
import LayoutHeader from '../components/LayoutHeader'
import PageContainer from '../components/PageContainer'
import styles from './index.less'

import {
  getAllCats,
} from '../reducers/feature'

function Layout() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllCats())
  }, [])

  return (
    <div className={styles.container}>
      <LayoutLeftSide />
      <LayoutHeader />
      <PageContainer />
    </div>
  )
}

export default Layout