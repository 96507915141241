import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import {
  queryCoupons as getList,
} from '../services/coupons'

  
export const queryCoupons = createAsyncThunk<any, any, any>(
  'coupon/get',
  async (params, {getState}) => {
    const querydata:any = getState()
    return await getList({
      ...querydata?.coupon?.querydata,
    })
  }
)

  
const coupon = createSlice({
  name: 'coupon',
  initialState: {
    list: [],
    querydata: {}
  },
  reducers: {
    getCoupondata(state, action) {
      state.querydata = action.payload
    },
  },
  extraReducers: {
    [queryCoupons.fulfilled as any]: (state, action) => {
      state.list = action.payload
    }
  }
})

const {
  reducer,
  actions
} = coupon;


export const {
  getCoupondata,
} = actions


export default reducer