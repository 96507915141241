
import fetch from './index'

export function getCertAddress(params): any {
  return fetch.get('commodities/certImage', {
    params,
  })
}

export function putHomeCommodityBySeller(id): any {
  return fetch.put(`commodities/sellers/home/${id}`)
}

export function getZB100Commodities(params): any {
  return fetch.get('commodities/sellers/rent/back', {
    params
  })
}

export function getSellerCommodities(params): any {
  return fetch.get('commodities/sellers/back', {
    params,
  })
}

export function putCommodityStatusBySeller(params): any {
  return fetch.put('commodities/sellers/down', params)
}

export function putCommoditySortBySeller(id): any {
  return fetch.put(`commodities/sellers/sort/top/${id}`)
}

export function putCommoditySortCancelBySeller(id): any {
  return fetch.put(`commodities/sellers/sort/top/cancel/${id}`)
}

export function putCommodityRevertBySeller(id): any {
  return fetch.put(`commodities/sellers/audit/${id}/1`)
}

export function postCommodityAuditBySeller(params): any {
  return fetch.post('commodities/sellers/audit', params)
}

export function putCommoditiesBySeller(id, params): any {
  return fetch.put(`commodities/sellers/${id}`, params)
}

export function postCommoditiesBySeller(params): any {
  return fetch.post('commodities/sellers', params)
}

export function snapshot(params): any {
  return fetch.get('commodities/snapshot', {
    params,
  })
}

export function getHomeCommodityVisibleBySeller(): any {
  return fetch.get('commodities/sellers/home/visible')
}

export function putHomeCommodityVisibleBySeller(visible): any {
  return fetch.put(`commodities/sellers/home/visible/${visible}`)
}

export function putTopHomeCommodityBySeller(id) {
  return fetch.put(`commodities/sellers/home/sort/top/${id}`)
}

export function putMoveHomeCommodityBySeller(commodityId, type) {
  return fetch.put(`commodities/sellers/home/move/${commodityId}/${type}`)
}

export function delHomeCommodityBySeller(id) {
  return fetch.delete(`commodities/sellers/home/${id}`)
}

export function getHomeCommodityBySeller(params) {
  return fetch.get('commodities/sellers/home', {
    params,
  })
}

export function batchAddToCustomize(params) {
  return fetch.post('commodities/sellers/home', params)
}

export function delCustomizeCommodities(params) {
  return fetch({
    method: 'DELETE',
    url: 'commodities/sellers/home',
    data: params
  })
}
