export const roles = [
  ['SELLER_ADMIN_ROOT', '管理员', 'SELLER_ADMIN'],
  ['SELLER_SALE_ROOT', '销售', 'SELLER_SALE'],
  ['SELLER_OPERATION_ROOT', '运营', 'SELLER_OPERATION'],
]

export function getRole(opTags = []) {
  return opTags.map(opTag => roles.find(role => role[0] === opTag)?.[1]).join('、')
}

export function isSeller(opTags = []) {
  return opTags.some(opTag => roles.some(role => role[0] === opTag))
}
