import axios from 'axios'

import {
  errorHandler,
} from '../utils'
import {
  get,
} from '../utils/session'

const fetch = axios.create({
  baseURL: '/api/'
})

fetch.interceptors.request.use(function (config) {
  const session = get();

  session.forEach(([key, value]) => {
    config.headers[key] = value
  })

  return config;
}, function (error) {
  return Promise.reject(error);
});

fetch.interceptors.response.use((response) => {
  const data = response?.data
  const code = data?.code

  if (code !== '200') {
    errorHandler(data)
    return Promise.reject(data)
  }

  return data?.data;
}, (error) => {
  const status = error?.response?.data?.data?.status

  errorHandler(error, status)

  return Promise.reject(error)
})

export default fetch