const routes: any = [{
  title: '首页',
  path: '/',
  key: 'HOME',
  help: false,
  link: '/'
}, {
  title: '商品编辑',
  path: '/seller_edit_commodity/:id',
  key: 'SELLER_EDIT_COMMODITY'
}, {
  title: '编辑优惠券',
  path: '/edit_coupon/:id',
  key: 'EDIT_COUPON'
}, {
  title: '新增优惠券',
  path: '/seller_add_coupon',
  key: 'SELLER_ADD_COUPON'
}, {
  title: '活动设置',
  path: '/seller_activity_setting',
  key: 'SELLER_ACTIVITY_SETTING'
}, {
  key: 'SELLER_HOME_SETTING',
  help: 'true'
}]

export default routes
