import fetch from './index'

export function getWish(): any {
    return fetch.get('/sellers/wish/back')
}

export function getSignup(): any {
    return fetch.post('/sellers/wish/back/signup')
}

export function getCancelSignup(): any {
    return fetch.delete('/sellers/wish/back/signup')
}

export function getActivity(): any {
    return fetch.get('/sellers/wish/back/data')
}

export function getPeoples(params): any {
    return fetch.get('/sellers/wish/back/peoples', {
        params
    })
}

export function setPrize(params): any {
    return fetch.post('/sellers/wish/back/prize', params)
}

export function editPrize(params): any {
    return fetch.put('/sellers/wish/back/prize', params)
}

export function deletePrize(id): any {
    return fetch.delete(`/sellers/wish/back/prize/${id}`)
}

export function getPrize(): any {
    return fetch.get('/sellers/wish/back/prizes')
}

export function getwinPrize(): any {
    return fetch.get('/sellers/wish/back/winner')
}

export function getrelease(id): any {
    return fetch.get(`/sellers/wish/back/prize/release/${id}`)
}

export function setReceiving(params): any {
    return fetch.post('/sellers/wish/back/prize/release/address', params)
}

export function getQRcode(params): any {
    return fetch.get('/mini/back/seller/qr/code', {
        params
    })
}






