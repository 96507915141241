import React from 'react'
import {
  Router,
  Switch,
  Route,
} from 'react-router-dom'
import {
  Provider,
} from 'react-redux'
import { ConfigProvider } from 'antd';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import zhCN from 'antd/es/locale/zh_CN';

import Layout from './Layout'
import Login from './Login'
import history from './utils/history'
import store from './utils/store'

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <ConfigProvider locale={zhCN}>
        <Provider store={store}>
          <Router history={history}>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/">
                <Layout />
              </Route>
            </Switch>
          </Router>
        </Provider>
      </ConfigProvider>
    </DndProvider>
  )
}

export default App